import { motion } from 'framer-motion';
import { popUp, svgDraw, svgFill } from '../Shape/animations';
import { circleVariants } from './HomePageAnimationCircle';
import { HomePageAnimationSvg } from './HomePageAnimationSvg';
import { useHomePageAnimation } from './useHomePageAnimation';

export const HomePageAnimationStep_Protect = () => {
  // Use the non-animated circle on mobile layout
  const isMinBreakpoint = useHomePageAnimation((s) => s.isMinBreakpoint);

  return (
    <HomePageAnimationSvg key={isMinBreakpoint ? 1 : 3}>
      {!isMinBreakpoint && (
        <motion.circle variants={popUp} custom={0} {...circleVariants.protect} />
      )}
      <motion.path
        custom={1}
        id="protectDiamondSm"
        d="M238 216.278L278.321 239.557L318.641 216.278L278.321 193L238 216.278Z"
        variants={svgFill}
        fill="#FDE5BB"
      />

      <motion.path
        custom={2}
        id="protectDiamondLg"
        d="M395 522.587L453.176 556.181L511.359 522.587L453.176 489L395 522.587Z"
        variants={svgFill}
        fill="#F6C55D"
      />

      <motion.path
        custom={3}
        id="protectCircleOutline"
        d="M489.484 355.592C489.484 385.857 477.462 414.885 456.061 436.29C434.66 457.696 405.632 469.726 375.36 469.739C345.08 469.739 316.04 457.713 294.629 436.306C273.218 414.899 261.189 385.865 261.189 355.592"
        variants={svgDraw}
        stroke="#EFADCD"
        strokeWidth="2.34613"
      />
    </HomePageAnimationSvg>
  );
};
