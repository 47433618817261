import { ArticleCard } from '@component/ArticleIndex/ArticleCard';
import Card, { CardProps } from '@component/Card';
import { Entry, isEntryType } from '@lib/api/fragments/entries';

export type EntryCardProps = CardProps<Entry>;

export const EntryCard = (props: EntryCardProps) => {
  const { title, summary, uri: href } = props.item ?? {};

  // const image = useDefaultImage(maybeImage);

  if (
    isEntryType(props.item, 'article') ||
    isEntryType(props.item, 'insight') ||
    isEntryType(props.item, 'caseStudy')
  )
    return <ArticleCard {...(props as CardProps<Entry<'article'>>)} />;

  // if (isEntryType(props.item, 'caseStudy'))
  // return <CaseStudyCard {...(props as CardProps<Entry<'caseStudy'>>)} />;

  return (
    <Card
      {...{
        hideImage: true,
        title,
        summary,
        href,
        ...props,
      }}
    />
  );
};
