import { motion } from 'framer-motion';
import { svgDraw, svgFill } from '../Shape/animations';
import { HomePageAnimationSvg } from './HomePageAnimationSvg';

export const HomePageAnimationStep_Home = () => {
  const diamondDelay = 0;
  const wavesDelay = 2;
  const circlesDelay = 1;
  const sunDelay = 3;

  return (
    <>
      <HomePageAnimationSvg layer="back">
        <g id="circleOutlines">
          <motion.path
            custom={circlesDelay + 0}
            d="M130.236 263.489C143.934 263.491 157.325 259.43 168.714 251.82C180.104 244.21 188.98 233.393 194.221 220.737C199.462 208.082 200.831 194.156 198.156 180.722C195.481 167.287 188.881 154.949 179.192 145.266C169.503 135.583 157.16 128.991 143.724 126.325C130.288 123.658 116.364 125.037 103.711 130.285C91.0588 135.534 80.2474 144.418 72.6447 155.812C65.0419 167.207 60.9895 180.6 61 194.298C61.0118 212.653 68.3114 230.252 81.2944 243.226C94.2773 256.201 111.881 263.489 130.236 263.489V263.489Z"
            variants={svgDraw}
            stroke="#5E7774"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <motion.path
            custom={circlesDelay + 0.5}
            d="M203.842 263.489C217.54 263.489 230.93 259.427 242.318 251.816C253.707 244.205 262.582 233.387 267.821 220.731C273.061 208.075 274.429 194.15 271.753 180.716C269.077 167.283 262.476 154.944 252.787 145.263C243.098 135.581 230.754 128.99 217.319 126.324C203.883 123.658 189.959 125.037 177.307 130.286C164.655 135.535 153.844 144.419 146.242 155.813C138.639 167.207 134.587 180.6 134.598 194.298C134.607 212.655 141.907 230.257 154.893 243.232C167.878 256.208 185.485 263.494 203.842 263.489V263.489Z"
            variants={svgDraw}
            stroke="#5E7774"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </g>
      </HomePageAnimationSvg>
      <HomePageAnimationSvg layer="front">
        <motion.path
          custom={diamondDelay}
          id="largeDiamond"
          d="M507.748 465.772L597.243 516.272L686.748 465.772L597.243 415.272L507.748 465.772Z"
          variants={svgFill}
          fill="#DD1D8D"
        />
        <motion.path
          custom={wavesDelay}
          id="wavesLower"
          d="M412.497 580.974L386.442 599.722C374.356 608.398 360 608.398 347.915 599.722L321.859 580.974C309.773 572.298 295.417 572.298 283.332 580.974L257.276 599.722C245.191 608.398 230.834 608.398 218.749 599.722L192.693 580.974C180.608 572.298 166.252 572.298 154.166 580.974L128.11 599.722C116.025 608.398 101.669 608.398 89.5829 599.722L63.5275 580.974C51.4418 572.298 37.0857 572.298 25 580.974"
          variants={svgDraw}
          stroke="#DD1D8D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
        <motion.path
          custom={wavesDelay + 0.5}
          id="wavesUpper"
          d="M412.497 533.507L386.442 552.255C374.356 560.931 360 560.931 347.915 552.255L321.859 533.507C309.773 524.831 295.417 524.831 283.332 533.507L257.276 552.255C245.191 560.931 230.834 560.931 218.749 552.255L192.693 533.507C180.608 524.831 166.252 524.831 154.166 533.507L128.11 552.255C116.025 560.931 101.669 560.931 89.5829 552.255L63.5275 533.507C51.4418 524.831 37.0857 524.831 25 533.507"
          variants={svgDraw}
          stroke="#DD1D8D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeMiterlimit="10"
        />
        <motion.g id="sun">
          <motion.path
            custom={sunDelay}
            id="sunCircle"
            d="M553.081 156.954C560.888 164.748 571.469 169.126 582.5 169.126C593.531 169.126 604.111 164.748 611.918 156.954C619.722 149.146 624.106 138.558 624.106 127.518C624.106 116.478 619.722 105.89 611.918 98.0811C608.057 94.2135 603.472 91.1457 598.425 89.0522C593.377 86.9587 587.965 85.8818 582.5 85.8818C577.035 85.8818 571.624 86.9587 566.576 89.0522C561.528 91.1457 556.942 94.2135 553.081 98.0811C545.277 105.89 540.893 116.478 540.893 127.518C540.893 138.558 545.277 149.146 553.081 156.954V156.954Z"
            variants={svgDraw}
            stroke="#F6C55D"
            strokeWidth="3"
            strokeLinecap="round"
          />
          {/* 6 */}
          <motion.path
            custom={sunDelay + 1}
            d="M582.5 219V192.466"
            variants={svgDraw}
            stroke="#F6C55D"
            strokeWidth="3"
            strokeLinecap="round"
          />
          {/* 12 */}
          <motion.path
            custom={sunDelay + 1}
            d="M582.5 62.5343V36"
            variants={svgDraw}
            stroke="#F6C55D"
            strokeWidth="3"
            strokeLinecap="round"
          />
          {/* 9 */}
          <motion.path
            custom={sunDelay + 1}
            d="M491 127.5H517.534"
            variants={svgDraw}
            stroke="#F6C55D"
            strokeWidth="3"
            strokeLinecap="round"
          />
          {/* 3 */}
          <motion.path
            custom={sunDelay + 1}
            d="M647.466 127.5H674"
            variants={svgDraw}
            stroke="#F6C55D"
            strokeWidth="3"
            strokeLinecap="round"
          />
          {/* 10 */}
          <motion.path
            custom={sunDelay + 1}
            d="M517.786 62.7869L536.569 81.57"
            variants={svgDraw}
            stroke="#F6C55D"
            strokeWidth="3"
            strokeLinecap="round"
          />
          {/* 5 */}
          <motion.path
            custom={sunDelay + 1}
            d="M628.431 173.43L647.214 192.213"
            variants={svgDraw}
            stroke="#F6C55D"
            strokeWidth="3"
            strokeLinecap="round"
          />
          {/* 2 */}
          <motion.path
            custom={sunDelay + 1}
            d="M647.214 62.7869L628.431 81.57"
            variants={svgDraw}
            stroke="#F6C55D"
            strokeWidth="3"
            strokeLinecap="round"
          />
          {/* 8 */}
          <motion.path
            custom={sunDelay + 1}
            d="M536.569 173.43L517.786 192.213"
            variants={svgDraw}
            stroke="#F6C55D"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </motion.g>
      </HomePageAnimationSvg>
    </>
  );
};
