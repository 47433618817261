import { colorValues } from '@theme/_vars.css';
import { motion } from 'framer-motion';
import { popUp, svgDraw, svgFill } from '../Shape/animations';
import { HomePageAnimationSvg } from './HomePageAnimationSvg';

export const HomePageAnimationStep_Grow = () => {
  return (
    <HomePageAnimationSvg key={1}>
      <motion.path
        id="growDiamond"
        d="M233 406.179L375.492 487.333L518 406.179L375.492 325.042L233 406.179Z"
        variants={svgFill}
        key={0}
        fill="#F5811F"
      />
      <motion.path
        id="growLine1"
        custom={0.5}
        d="M233 446.167L375.492 526.917L518 446.167"
        variants={svgDraw}
        stroke="#FDCB9C"
        strokeWidth="2.23218"
      />
      <motion.path
        custom={1}
        id="growLine2"
        d="M233 476.25L375.492 557L518 476.25"
        variants={svgDraw}
        stroke="#FDCB9C"
        strokeWidth="2.23218"
      />
      <motion.circle
        variants={popUp}
        custom={2}
        {...{
          cx: 375.5,
          cy: 236.209,
          r: 42.75,
          fill: colorValues.yellow,
        }}
      />
    </HomePageAnimationSvg>
  );
};
