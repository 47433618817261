import Container from '@component/Container';
import Grid from '@component/Grid';
import RelatedEntries from '@component/RelatedEntries';
import { createLayout } from '@layout/Layout/createLayout';
import * as styles from './LayoutDefault.css';

const LayoutDefault = createLayout(
  ['header', 'beforeBody', 'afterBody', 'body', 'sidebar', 'footer'],
  (props, { slots }) => {
    const { header, beforeBody, afterBody, body, sidebar, footer } = slots ?? {};
    return (
      <>
        {header}
        <Container className={styles.container}>
          <Grid className={styles.grid}>
            {beforeBody && (
              <Grid.Col className={styles.beforeBodyCol} lg={8}>
                {beforeBody}
              </Grid.Col>
            )}
            <Grid.Col className={styles.contentCol} lg={8}>
              {body}
            </Grid.Col>
            <Grid.Col
              className={styles.sidebarCol}
              component="aside"
              lg={4}
              xl={3}
              mx={3}
              offsetXl={1}>
              {sidebar}
            </Grid.Col>
            {afterBody && (
              <Grid.Col className={styles.afterBodyCol} lg={8}>
                {afterBody}
              </Grid.Col>
            )}
          </Grid>
        </Container>

        {footer ?? <RelatedEntries key="relatedEntries" />}
      </>
    );
  }
);

export default LayoutDefault;
