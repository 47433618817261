import { colorValues } from '@theme/index';
import { motion, transform } from 'framer-motion';
import { HomePageAnimationSvg } from './HomePageAnimationSvg';
import { HomePageBannerImage } from './HomePageBannerImage';
import { useHomePageAnimation, WelcomeStep, WelcomeVariantName } from './useHomePageAnimation';

const outer = 750;
const inner = 500;
const centerX = outer / 2;
const centerY = outer / 2;

// const delay = 0;

// const circleTransition: Variant = {
//   transition: {
//     cx: { delay: delay + 0.5, type: 'spring', duration: 1.5, inertia: 1 },
//     cy: { delay: delay + 0.5, type: 'spring', duration: 1.5, inertia: 1 },
//     r: { delay, type: 'spring', duration: 1 },
//     fill: { delay, type: 'spring', duration: 1.5 },
//   },
// };

type CircleStyle = {
  cx: number;
  cy: number;
  r: number;
  fill: string;
  // opacity
};

export const circleVariants: Record<WelcomeVariantName, CircleStyle> = {
  hidden: {
    cx: centerX,
    cy: centerY,
    r: 1,
    fill: colorValues.orange,
  },
  home: {
    cx: centerX,
    cy: centerY,
    r: inner / 2,
    fill: colorValues.orange,
  },
  protect: {
    cx: centerX,
    cy: 353.5,
    r: 97,
    fill: colorValues.pink,
  },
  grow: {
    // cx: 375.5,
    // cy: 236.209,
    // r: 42.75,
    // fill: colorValues.yellow,
    cx: centerX,
    cy: 353.5,
    r: 0,
    fill: `rgba(246, 197, 93, 0)`,
  },
};

export type HomePageAnimationSvgCircleProps = {
  step?: WelcomeStep;
};

export const HomePageAnimationSvgCircle = ({ step }: HomePageAnimationSvgCircleProps) => {
  return <motion.circle variants={circleVariants} animate={step} />;
};

export const useHomePageAnimationCircleStyle = () => {
  const { progress } = useHomePageAnimation((s) => ({
    progress: s.springyStep,
  }));

  const styleProgress = progress;
  const final = 1.2;
  const style = {
    cx: transform(
      [0, 1, final],
      [circleVariants.home['cx'], circleVariants.protect['cx'], circleVariants.grow['cx']]
    )(styleProgress),
    cy: transform(
      [0, 1, final],
      [circleVariants.home['cy'], circleVariants.protect['cy'], circleVariants.grow['cy']]
    )(styleProgress),
    r: transform(
      [0, 1, final],
      [circleVariants.home['r'], circleVariants.protect['r'], circleVariants.grow['r']]
    )(styleProgress),
    fill: transform(
      [0, 1, final],
      [circleVariants.home['fill'], circleVariants.protect['fill'], circleVariants.grow['fill']]
    )(styleProgress),
  };

  return style;
};

export const HomePageAnimationCircle = () => {
  // const step = useHomePageAnimation((s) => s.currentStep);
  const style = useHomePageAnimationCircleStyle();

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <HomePageAnimationSvg layer="middle">
        <motion.circle animate={style} />
      </HomePageAnimationSvg>
      <HomePageBannerImage />
    </motion.div>
  );
};
