import { createLayout } from '@layout/Layout';

const LayoutLanding = createLayout(['header', 'body', 'footer'], (props, { slots }) => {
  const { header, body, footer } = slots ?? {};

  return (
    <>
      {header}
      {body}
      {footer}
    </>
  );
});

export default LayoutLanding;
