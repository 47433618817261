import { usePageTitle } from '@component/PageHeader';
import { Rhombus } from '@component/Shape';
import { useView } from '@lib/store';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { motion } from 'framer-motion';
import { useRef } from 'react';
import { HomePageAnimationCircle } from './HomePageAnimationCircle';
import { HomePageAnimationStep } from './HomePageAnimationStep';
import { HomePageAnimationStep_Grow } from './HomePageAnimationStep_Grow';
import { HomePageAnimationStep_Home } from './HomePageAnimationStep_Home';
import { HomePageAnimationStep_Protect } from './HomePageAnimationStep_Protect';
import * as styles from './HomePageHeader.css';
import { StepContentProps } from './StepContent';
import {
  createHomePageAnimationStore,
  HomePageAnimationContextProvider,
  useHomePageHeaderObserver,
} from './useHomePageAnimation';

const HomePageHeaderInner = () => {
  // -----------------------------------------------------------------------------------
  // ---- Get the content ----

  // Frame 0
  const view = useView();
  const linkCta = maybeGet(view, 'linkCta');
  const summary = maybeGet(view, 'summary');
  const [frame1, frame2] = maybeGet(view, 'homePageCtas') ?? [];

  // Frame 1 Props
  const frame0Props: StepContentProps = {
    heading: usePageTitle(),
    content: summary,
    linkCta: linkCta,
    color: 'pink',
  };
  // Frame 1 Props
  const frame1Props: StepContentProps = {
    color: 'pink',
    ...frame1,
  };

  // Frame 2
  const frame2Props: StepContentProps = {
    color: 'orange',
    ...frame2,
  };
  // -----------------------------------------------------------------------------------

  const ref = useRef<HTMLDivElement>(null);
  useHomePageHeaderObserver(ref);

  return (
    <motion.header ref={ref} className={styles.root({})}>
      <Rhombus
        key={0}
        className={styles.backgroundShape}
        initial={['fade']}
        // animate={currentStep > 0 ? 'fade' : 'visible'}
        transitionProps={{
          duration: 'xl',
          order: 2,
        }}
      />
      <HomePageAnimationStep variant="secondary" fixed stepNumber={null}>
        <HomePageAnimationCircle />
      </HomePageAnimationStep>
      <HomePageAnimationStep variant="primary" stepNumber={0} stepContent={frame0Props}>
        <HomePageAnimationStep_Home />
      </HomePageAnimationStep>
      <HomePageAnimationStep variant="secondary" stepNumber={1} stepContent={frame1Props}>
        <HomePageAnimationStep_Protect />
      </HomePageAnimationStep>
      <HomePageAnimationStep
        variant="secondary"
        layout="reverse"
        stepNumber={2}
        stepContent={frame2Props}>
        <HomePageAnimationStep_Grow />
      </HomePageAnimationStep>
    </motion.header>
  );
};

const HomePageHeader = () => {
  return (
    <HomePageAnimationContextProvider key={1} createStore={createHomePageAnimationStore}>
      <HomePageHeaderInner />
    </HomePageAnimationContextProvider>
  );
};

export default HomePageHeader;
