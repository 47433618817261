import { useBreakpointMin } from '@theme/index';
import { MotionValue, transform, useScroll, useSpring, Variant } from 'framer-motion';
import { createContext, RefObject, useContext, useEffect } from 'react';
import createStore, { StoreApi } from 'zustand';
import createZustandContext from 'zustand/context';

export const welcomeSteps = ['home', 'protect', 'grow'] as const;

export type WelcomeStep = typeof welcomeSteps[number];

export type WelcomeVariants<T extends Variant = Variant> = Record<WelcomeStep | 'hidden', T>;
export type WelcomeVariantName = keyof WelcomeVariants;

export type HomePageAnimationState = {
  isMinBreakpoint: boolean;
  springyStep: number;
  progressMotion: MotionValue | null;
  progress: number;
  scrollY: number;
  nSteps: number;
  currentStep: number;
  currentStepName: WelcomeStep;
  currentStepProgress: number;
  setIsMinBreakpoint: (n: boolean) => void;
  setSpringyStep: (n: number) => void;
  setProgressMotion: (n: MotionValue) => void;
  setProgress: (n: number) => void;
  setScrollY: (n: number) => void;
  setStep: (n: number) => void;
  setStepProgress: (n: number) => void;
};
// const init = false;

export const createHomePageAnimationStore = () =>
  createStore<HomePageAnimationState>((set) => ({
    isMinBreakpoint: true,
    springyStep: 0,
    progressMotion: null,
    progress: 0,
    scrollY: 0,
    nSteps: 3,
    currentStep: 0,
    currentStepProgress: 0,
    currentStepName: 'home',

    setIsMinBreakpoint: (isMinBreakpoint) => set({ isMinBreakpoint }),
    setSpringyStep: (springyStep) => set({ springyStep }),
    setProgressMotion: (progressMotion) => set({ progressMotion }),
    setProgress: (progress) => set({ progress }),
    setScrollY: (scrollY) => set({ scrollY }),
    setStep: (currentStep) => set({ currentStep, currentStepName: welcomeSteps[currentStep] }),
    setStepProgress: (currentStepProgress) => set({ currentStepProgress }),
  }));

export const { Provider: HomePageAnimationContextProvider, useStore: useHomePageAnimation } =
  createZustandContext<StoreApi<HomePageAnimationState>>();

export type StepContextProps = {
  /** Is the first step */
  isFirst: boolean;
  /** Is the last step */
  isLast: boolean;
  /** Entrance is complete  */
  isComplete: boolean;
  /** Index of step (from zero) */
  isCurrent: boolean;
  /** Index of step (from zero) */
  stepNumber: number | null;
  /** Scroll progress in relation to this step, where:
   * -1 is at the previous step,
   * 0 is center
   * 1 is at the next step
   */
  progress: number;
};

export const StepContext = createContext<StepContextProps>({
  isComplete: false,
  isCurrent: false,
  isFirst: false,
  isLast: false,
  stepNumber: null,
  progress: 0,
});

export const useHomePageAnimationStep = () => useContext(StepContext);

export const useHomePageHeaderObserver = (ref: RefObject<HTMLDivElement>) => {
  const {
    nSteps,
    setStep,
    setSpringyStep,
    setProgress,
    setStepProgress,
    setScrollY,
    setIsMinBreakpoint,
  } = useHomePageAnimation(
    ({
      nSteps,
      setStep,
      setSpringyStep,
      setProgress,
      setStepProgress,
      setScrollY,
      setIsMinBreakpoint,
    }) => ({
      nSteps,
      setStep,
      setSpringyStep,
      setProgress,
      setStepProgress,
      setScrollY,
      setIsMinBreakpoint,
    })
  );

  // -----------------------------------------------------------------------------------

  const isMinBreakpoint = useBreakpointMin('md');

  useEffect(() => {
    setIsMinBreakpoint(isMinBreakpoint);
  }, [isMinBreakpoint, setIsMinBreakpoint]);

  const { scrollYProgress, scrollY } = useScroll({
    target: ref,
    offset: ['start start', 'end end'], // offset: ['start end', 'end end'], // offset: ['start start', 'end start'],
  });

  const stepSpring = useSpring(0, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => stepSpring.onChange(setSpringyStep), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => scrollY.onChange(setScrollY), []);

  useEffect(
    () =>
      scrollYProgress.onChange((progress) => {
        /**
         * Transform scroll progress into step numbers,
         * Assuming we start at 0 and end at the last step (without an exit animation)
         */
        const stepProgress = transform([0, 1], [0, nSteps - 1])(progress);

        // console.log({ stepProgress, step });
        const stepRounded = Math.round(stepProgress);

        /**
         * Global step animation
         */
        stepSpring.set(stepRounded);

        const stepN = Math.floor(stepProgress);
        const currentStepProgress = stepProgress - stepN;

        setProgress(progress);
        setStep(stepRounded);
        setStepProgress(currentStepProgress);
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
