import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';
import * as styles from './HomePageHeader.css';
import { useHomePageAnimationStep } from './useHomePageAnimation';

const outer = 750;
const inner = 500;
const left = (outer - inner) / 2;
const top = (outer - inner) / 2;
const right = inner;
const bottom = inner;

export type HomePageAnimationSvgProps = PropsWithChildren<
  {
    //
  } & styles.ArtVariants
>;

export const HomePageAnimationSvg = ({
  layer = 'middle',

  children,
}: HomePageAnimationSvgProps) => {
  //
  const { isCurrent } = useHomePageAnimationStep();
  const visible = isCurrent;

  return (
    <motion.svg
      className={styles.art({ layer })}
      viewBox={`${left} ${top} ${right} ${bottom}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial="hidden"
      animate={visible ? 'visible' : 'hidden'}>
      {children}
    </motion.svg>
  );
};
