import Box from '@component/Box';
import CardGrid from '@component/CardGrid';
import Container from '@component/Container';
import { EntryCard } from '@component/EntryIndex/EntryCard';
import Text from '@component/Text';
import { Entry } from '@lib/api/fragments/entries';
import { useView } from '@lib/store';
import { maybeGet } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import React from 'react';
import type { RelatedEntriesVariants } from './RelatedEntries.css';

export type RelatedEntriesProps = React.PropsWithChildren<
  RelatedEntriesVariants & {
    /**/
  }
>;

const RelatedEntries = (_props: RelatedEntriesProps) => {
  const t = useTranslations('common');
  const view = useView();

  const relatedEntries = (maybeGet(view, 'relatedEntries') ?? []) as Entry[];

  return relatedEntries?.length ? (
    <Box backgroundColor="greyLight" paddingY="2xl">
      <Container>
        <Text variant="h1" component="h2" color="primary" sx={{ mb: '1em' }}>
          {t('relatedContent')}
        </Text>

        <CardGrid
          CardProps={{ backgroundColor: 'white' }}
          md={6}
          xl={4}
          items={relatedEntries}
          Component={EntryCard}
        />
      </Container>
    </Box>
  ) : (
    <></>
  );
};

export default RelatedEntries;
