import Container from '@component/Container';
import Grid, { GridColProps } from '@component/Grid';
import classNames from 'classnames';
import { motion, transform } from 'framer-motion';
import { PropsWithChildren } from 'react';
import * as styles from './HomePageHeader.css';
import { StepContent, StepContentProps } from './StepContent';
import {
  StepContext,
  useHomePageAnimation,
  useHomePageAnimationStep,
} from './useHomePageAnimation';

export type HomePageAnimationStepProps = PropsWithChildren<
  {
    stepNumber: number | null;
    stepContent?: StepContentProps;
  } & styles.StepVariants
>;

export const HomePageAnimationStepInner = ({
  stepContent,
  fixed,
  children,
  variant,
  layout,
}: HomePageAnimationStepProps) => {
  const { isFirst, isLast, isComplete, isCurrent } = useHomePageAnimationStep();

  const isPrimary = variant === 'primary';
  const isReversed = layout === 'reverse';

  const contentColProps: GridColProps = {
    md: 5,
    ...(!isPrimary &&
      !isReversed && {
        offsetMd: 1,
      }),
  };

  const artColProps: GridColProps = { xs: 12, md: 6 };

  return (
    <motion.div
      className={classNames(
        styles.step({
          variant,
          layout,
          fixed,
          isFirst,
          isLast,
          isComplete,
          isCurrent,
          /*
           */
        })
      )}>
      <Container>
        <Grid className={styles.stepGrid}>
          <Grid.Col {...contentColProps} className={styles.stepContentCol}>
            <StepContent variant={variant} {...stepContent} />
          </Grid.Col>
          <Grid.Col {...artColProps} className={styles.stepArtCol}>
            <motion.div className={styles.artWrap}>{children}</motion.div>
          </Grid.Col>
        </Grid>
      </Container>
    </motion.div>
  );
};

export const HomePageAnimationStep = (props: HomePageAnimationStepProps) => {
  const { progress: totalProgress, nSteps, currentStep } = useHomePageAnimation();

  const stepNumber = props.stepNumber ?? 1;
  const stepsProgress = transform([0, 1], [0, nSteps - 1])(totalProgress);
  const progress = transform([stepNumber - 0.5, stepNumber + 0.5], [-1, 1])(stepsProgress);

  return (
    <StepContext.Provider
      value={{
        stepNumber,
        progress,
        isCurrent: currentStep === stepNumber,
        isFirst: stepNumber === 0,
        isLast: stepNumber === nSteps - 1,
        isComplete: progress >= 0,
      }}>
      <HomePageAnimationStepInner {...props} />
    </StepContext.Provider>
  );
};
