import Button from '@component/Button';
import Link from '@component/Link';
import Text from '@component/Text';
import { Link as LinkType } from '@lib/api/fragments/linkField';
import { Color } from '@theme/_vars.css';
import { motion, MotionProps, transform } from 'framer-motion';
import * as styles from './HomePageHeader.css';
import { useHomePageAnimation, useHomePageAnimationStep } from './useHomePageAnimation';

export type StepContentProps = MotionProps & {
  color?: Color;
  content?: Maybe<string>;
  heading?: Maybe<string>;
  label?: Maybe<string>;
  linkCta?: Maybe<LinkType>;
  variant?: styles.StepVariants['variant'];
};

export const StepContent = ({
  color,
  content,
  heading,
  label,
  linkCta,
  variant = 'secondary',
  ...props
}: StepContentProps) => {
  const isMinBreakpoint = useHomePageAnimation((s) => s.isMinBreakpoint);

  const { progress: myProgress, isFirst, isLast } = useHomePageAnimationStep();

  const isPrimary = variant === 'primary';
  const hasLink = !!linkCta?.href;

  // First step should start at full opacity
  const startOpacity = isFirst ? 1 : 0;

  const fadeInFrom = -1;
  const fadeInBy = -0.99;
  const fadeOutFrom = 0.95;
  const fadeOutBy = 1;

  const startOffset = isFirst ? 0 : 50; // First step shouldn't scroll in
  const inOffsetStart = 5;
  const inOffsetEnd = -10;
  const endOffset = isLast ? 0 : -50; // Last step shouldn't scroll out

  const scrollInFrom = isFirst ? 0 : -1; //0.95;
  const scrollInBy = -0.95; // -0.6;
  const scrollOutFrom = 0.5; //0.1;
  const scrollOutBy = 1;

  const opacity = transform(
    [fadeInFrom, fadeInBy, fadeOutFrom, fadeOutBy],
    [startOpacity, 1, 1, 0]
  )(myProgress);

  const yTarget = transform(
    [scrollInFrom, scrollInBy, scrollOutFrom, scrollOutBy],
    [startOffset, inOffsetStart, inOffsetEnd, endOffset]
  )(myProgress);

  const animate = isMinBreakpoint ? { y: `${yTarget}vh`, opacity } : { y: 0 };

  return (
    <motion.div
      // key={2}
      // {...(isMinBreakpoint && !isFirst && { initial: { y: '100vh' } })}
      initial={{ y: isFirst ? 0 : '100vh' }}
      animate={animate}
      transition={{
        ease: 'easeOut',
      }}
      {...props}>
      {label && (
        <Text color={color} bold uppercase marginBottom={2}>
          {label}
        </Text>
      )}
      <Text variant={isPrimary ? 'h1' : 'h2'} color="primary" html>
        {heading}
      </Text>
      <Text variant="large" html>
        {content}
      </Text>
      {hasLink &&
        (isPrimary ? (
          <Button sx={{ mt: 3 }} {...linkCta} />
        ) : (
          <Link
            mt={3}
            variant="buttonLg"
            {...linkCta}
            color="primary"
            underline="always"
            underlineColor={color}
          />
        ))}
    </motion.div>
  );
};
