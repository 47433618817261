import { createStaticPropsFetcher, getStaticPaths as utilGetStaticPaths } from '@api';
import Blocks from '@component/Blocks';
import HomePageHeader from '@component/HomePageHeader';
import PageHeader, { PageHeaderSimple } from '@component/PageHeader';
import RelatedEntries from '@component/RelatedEntries';
import LayoutDefault from '@layout/LayoutDefault';
import LayoutLanding from '@layout/LayoutLanding';
import { useViewCallback } from '@lib/store';
import type { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';

const PageView: NextPage = () => {
  const path = useRouter().asPath;
  const isHomePage = useViewCallback('isHomePage');
  const isLandingTemplate = useViewCallback('isLandingTemplate');

  return isHomePage || isLandingTemplate ? (
    <LayoutLanding
      key={path}
      slots={{
        header: isHomePage ? <HomePageHeader /> : <PageHeader />,
        body: <Blocks />,
      }}
    />
  ) : (
    <LayoutDefault
      slots={{
        header: <PageHeaderSimple />,
        body: [<Blocks key="blocks" />],
        footer: <RelatedEntries key="relatedEntries" />,
      }}
    />
  );
};

export default PageView;

export const getStaticProps: GetStaticProps = createStaticPropsFetcher('page');

export const getStaticPaths: GetStaticPaths = async () =>
  await utilGetStaticPaths({ entrySection: 'page' });
