import Image from '@component/Image';
import { useViewBannerImage } from '@lib/store';
import { motion, transform } from 'framer-motion';
import { circleVariants, useHomePageAnimationCircleStyle } from './HomePageAnimationCircle';
import * as styles from './HomePageHeader.css';
import { useHomePageAnimation } from './useHomePageAnimation';

export const HomePageBannerImage = () => {
  const bannerImage = useViewBannerImage();
  // const bannerImage = {
  //   src: '/home-animation.webp',
  //   width: 998,
  //   height: 998,
  // };

  const { progress } = useHomePageAnimation((s) => ({
    progress: s.springyStep,
  }));

  const styleProgress = progress;

  const { cx: _fullX, cy: fullY, r: fullR } = circleVariants.home;
  const { cy, r } = useHomePageAnimationCircleStyle();

  const radius = (r / fullR) * 50;
  const y = (cy / fullY) * 50;

  const wrapStyle = {
    // clipPath: transform([0, 1], [`circle(50%)`, 'circle(0%)'])(styleProgress),
    clipPath: `circle(${radius}% at 50% ${y}%)`,
  };

  const style = {
    y: transform([0, 1], [`0`, '-50%'])(styleProgress),
    clipPath: `circle(${radius}% at 50% ${y}%)`,
    // clipPath: transform([0, 1], [`circle(50%)`, 'circle(0%)'])(styleProgress),
  };

  return (
    <motion.div className={styles.imageMask} style={wrapStyle}>
      <motion.div className={styles.imageWrap} style={style}>
        <Image
          {...{
            alt: '',
            ...bannerImage,
            fixedRatio: true,
            priority: true,
            transform: { xs: 'squareLgCrop', mx: 'squareXlCrop' },
            // transform: 'original',
          }}
        />
      </motion.div>
    </motion.div>
  );
};
